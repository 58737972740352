import { User } from './types';

/**
 * @deprecated - TODO: delete this once types here and in BE are synced
 */
export const mapUserFromPayload = (payload: User) => ({
  name: payload.name,
  id: payload.id,
  balance: payload.balance,
  deposit: payload.deposit,
  qrCode: payload.qrCode,
  isActiveTransaction: payload.isActiveTransaction,
  is_active_payout: payload.is_active_payout,
  is_payout_permitted: payload.is_payout_permitted,
  is_notification_enabled: payload.is_notification_enabled,
  frozen_limit: payload.frozen_limit,
  tax: payload.tax,
  tg_Nick: payload.tg_Nick,
  banned_frozen_limit: payload.banned_frozen_limit,
  EPocket_number: payload.EPocket_number,
  deposit_wallet: payload.deposit_wallet,
  mobile_token: payload.mobile_token,
  mono_tokens: payload.mono_tokens,
  mono_token: payload.mono_token,
  acquiring_tax: payload.acquiring_tax,
  manual_payout_capacity: payload.manual_payout_capacity,
  payout_capacity: payload.payout_capacity,
  daily_payout: payload.daily_payout,
  payout_course: payload.payout_course,
  payout_tax: payload.payout_tax,
  operator_callback_url: payload.operator_callback_url,
  base_currency: payload.base_currency,
});
