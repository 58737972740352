import { Box, List, ListDivider, ListItem, ListItemContent, Typography, Button } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { initialFilterState } from '../../../pages/History/History';
import { getActivePayouts, getOperatorById } from '../../../redux/auth/operations';
import { getGlobalUser } from '../../../redux/auth/selectors';
import { AppDispatch } from '../../../redux/store';
import { getAllPayouts, processPayoutStatus, updatePayout } from '../../../services/operators';
import { formatDateTime } from '../../../utils/dateFormater';
import { maskCreditCardNumber } from '../../../utils/formatCreditCard';
import { Payout } from '../../../utils/types';
import ModalConfirm from '../../../pages/ActivePayouts/ModalConfirm';

const PendingItem = ({ listItem }: { listItem: Payout }) => {
  const { t } = useTranslation();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [receiptNumber, setReceiptNumber] = useState('');
  const [receiptUrl, setReceiptUrl] = useState('');
  const [image, setImage] = useState<File[] | null>(null);

  const { user } = useSelector(getGlobalUser);
  const dispatch = useDispatch<AppDispatch>();

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setImage(filesArray);
    }
  };

  const handleAcceptPayout = async () => {
    try {
      await updatePayout(listItem.id, listItem?.amount, receiptNumber ?? listItem?.receipt_number, receiptUrl ?? listItem.receipt_url, image ?? null);
        const fetch = async () => {
          const data = await getAllPayouts(user.id as number, null, null, {
            ...initialFilterState,
            searchStatus: 'PROCESSING',
          });

          dispatch(getActivePayouts(data.payouts));
        };
        fetch();
        dispatch(getOperatorById(user.id));
    } catch {
        toast.error(t('toast_AcceptPayout'));
    }
  };

  const toggleOpenModal = async () => {
    if (listItem.status === 'PENDING' && openModalConfirm) {
      await processPayoutStatus(listItem.id);
    }
    if (openModalConfirm) {
      const data = await getAllPayouts(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PROCESSING',
      });
      dispatch(getActivePayouts(data.payouts));
    }
    setOpenModalConfirm(!openModalConfirm);
  };

  const handleChange = (value: string) => {
    setReceiptNumber(value);
  };

  const handleChangeReceiptUrl = (value: string) => {
    setReceiptUrl(value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    toggleOpenModal();
    handleAcceptPayout();
  };

  return (
      <>
          <List
              size="sm"
              sx={{
                  position: 'relative',
                  '--ListItem-paddingX': 0,
              }}
          >
              <ListItem
                  sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                      position: 'relative',
                  }}
              >
                  <ListItemContent>
                      <Box sx={{ mt: 1 }}>
                          <Typography level="body-xs">{t('history_table_card')}</Typography>
                          <Typography level="body-xs">{maskCreditCardNumber(listItem.pocket_address)}</Typography>
                      </Box>
                      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <Typography level="body-xs">{t('history_table_startDate')}</Typography>
                          <Typography level="body-md">{formatDateTime(listItem.created_at.toString())}</Typography>
                      </Box>
                      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <Typography level="body-xs">{t('history_table_priceUah', {
                            currency: (localStorage.getItem('base_currency')) || '',
                          })}</Typography>
                          <Typography level="body-md">{Number(listItem.amount).toFixed(2)}</Typography>
                      </Box>
                      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <Typography level="body-xs">{t('active_table_course')}</Typography>
                          <Typography level="body-md">{Number(listItem.operator_course).toFixed(2)}</Typography>
                      </Box>
                      <Button
                          variant="plain"
                          onClick={toggleOpenModal}
                          sx={{
                              position: 'absolute', top: 0, right: 0,
                              display: 'block', color: '#947EFE', fontSize: '16px', fontWeight: 500, borderBottom: '1px solid #947EFE',
                              p: 0,
                              borderRadius: 0,
                              m: 0,
                          }}
                      >
                          {t('pending_payout_proceed')}
                      </Button>
                  </ListItemContent>
              </ListItem>
              <ListDivider />
          </List>
        <ModalConfirm
          openModal={openModalConfirm}
          toggleOpenModal={toggleOpenModal}
          onSubmit={onSubmit}
          handleChange={handleChange}
          payout={listItem}
          handleChangeReceiptUrl={handleChangeReceiptUrl}
          handleChangeImage={handleChangeImage}
        />
      </>
  );
};

export default PendingItem;
